import useSWR, { useSWRInfinite } from 'swr';
import { AxiosResponse, AxiosError } from 'axios';

import { API } from '../index';
import { APIErrorResponse } from '../../types/common';
import {
  SWRCustomConfig,
  GetRequest,
  SWRCustomInfiniteReturn,
  SWRCustomReturn,
  SWRCustomInfiniteConfig,
} from '../../types/swr';

export default function useRequest<Data = unknown, Error = APIErrorResponse>(
  request: GetRequest,
  { initialData, skipFetch, ...config }: SWRCustomConfig<Data, Error> = {}
): SWRCustomReturn<Data, Error> {
  const { data: response, error, isValidating, revalidate, mutate } = useSWR<
    AxiosResponse<Data>,
    AxiosError<Error>
  >(
    skipFetch
      ? null
      : request && JSON.stringify({ url: request.url, params: request.params }),
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => API(request!),
    {
      ...config,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        config: request!,
        headers: {},
        data: initialData,
      },
    }
  );

  return {
    data: response?.data,
    response,
    error,
    isValidating,
    revalidate,
    mutate,
  };
}

export function useRequestInfinite<Data = unknown, Error = APIErrorResponse>(
  getRequest: (
    size: number,
    previousPageData: AxiosResponse<Data> | null
  ) => GetRequest,
  {
    initialData,
    skipFetch,
    ...config
  }: SWRCustomInfiniteConfig<Data, Error> = {}
): SWRCustomInfiniteReturn<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    revalidate,
    mutate,
    size,
    setSize,
  } = useSWRInfinite<AxiosResponse<Data>, AxiosError<Error>>(
    /**
     * NOTE: If getRequest returns null, useSWRInfinite adds an empty array to the end of response
     */
    (index, previousPageData) => {
      if (skipFetch) {
        return null;
      }

      const request = getRequest(index, previousPageData);

      return request
        ? JSON.stringify({ url: request.url, params: request.params })
        : null;
    },
    (request) => API(JSON.parse(request)),
    {
      ...config,
      initialData:
        initialData &&
        initialData.map((data) => ({
          status: 200,
          statusText: 'InitialData',
          config: {},
          headers: {},
          data,
        })),
    }
  );

  return {
    data: response?.map((r) => r.data),
    response,
    error,
    isValidating,
    revalidate,
    mutate,
    size,
    setSize,
  };
}
