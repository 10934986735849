import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import cn from 'classnames';

import { Button, GlobalContext, IconButton } from '../../common';
import NextLink from '../../common/next-link/NextLink';
import { NAVIGATION_ITEMS } from '../../../constants/common';

import styles from './Footer.module.scss';

const DynamicJoinUsModal = dynamic(() => import('./JoinUsModal'));

type FooterProps = {
  noVerticalMargin?: boolean;
};

const Footer = ({ noVerticalMargin = false }: FooterProps) => {
  const { openModal } = useContext(GlobalContext);

  const router = useRouter();

  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={`container ${!noVerticalMargin && 'mt-20 mb-28 lg:mb-20'}`}
    >
      <div className="relative">
        <div className="w-full border-t border-gray-dark" aria-hidden="true" />
        <IconButton
          a11yLabel="Scroll up"
          icon="scroll-up"
          iconAlt="arrow up"
          className={styles.scrollUpButton}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      </div>

      <div
        className={cn(styles.wrapperSimple, {
          [styles.homepage]: router.pathname === '/',
        })}
      >
        <ul>
          {NAVIGATION_ITEMS.map(({ label, href }, key) => (
            <li key={key} className="inline mr-10">
              <NextLink kind="secondary" href={href} className="text-xs">
                {label}
              </NextLink>
            </li>
          ))}
        </ul>

        <div className="flex items-center">
          <p className="mr-5 text-xs font-medium">Are you a restaurant?</p>
          <Button size="small" onClick={() => openModal('join_us')}>
            Join us!
          </Button>
        </div>

        <div className="text-xs lg:text-right text-brown-light">
          &copy; Copyright Ktown Inc {currentYear}
        </div>
      </div>

      <div
        className={cn('flex', styles.wrapperExtended, {
          // Hidden on desktop pages other than "homepage"
          [styles.homepage]: router.pathname === '/',
          [styles.checkout]: router.pathname === '/checkout',
        })}
      >
        <div
          className={cn('mb-5 lg:mb-0', {
            hidden: router.pathname === '/checkout',
          })}
        >
          <h2 className="mb-4 text-sm font-semibold">Our Mission</h2>
          <p className={styles.paragraph}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            luctus libero id ipsum pellentesque, non scelerisque ex facilisis.
            Cras consequat massa vitae sem viverra, id maluada libero bibendum.
            Nam in lectus quis dui tristique commodo vitae id odio.
          </p>
          <p className={styles.paragraph}>
            Nam pretium, justo at sodales feugiat efficitur risus in leo
            condimentum, eget interdum est finibus. Magna sapien pellentesque
            magna, eu venenatis tortor tellus a urna. Quisque gravida ac purus
            eu consequat. Donec erat lectus, sollicitudin a dapibus ut,
            porttitor sed justo. Fusce fringilla interdum odio, in imperdiet
            lorem efficitur eu. Nunc sem ante, aliquet eget libero.
          </p>
        </div>

        <div
          className={cn('flex flex-col justify-between', {
            'items-center': router.pathname === '/checkout',
          })}
        >
          <div className="lg:flex mb-10">
            <div className="flex mb-10 lg:mr-28">
              <ul className="mr-20 space-y-4">
                {NAVIGATION_ITEMS.slice(0, 4).map(({ label, href }, key) => (
                  <li key={key}>
                    <NextLink kind="secondary" href={href} className="text-sm">
                      {label}
                    </NextLink>
                  </li>
                ))}
              </ul>

              <ul className="space-y-4">
                {NAVIGATION_ITEMS.slice(4).map(({ label, href }, key) => (
                  <li key={key}>
                    <NextLink kind="secondary" href={href} className="text-sm">
                      {label}
                    </NextLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.cta}>
              <p className="mb-4 lg:mb-5 text-xs font-medium">
                Are you a restaurant?
              </p>
              <Button size="small" onClick={() => openModal('join_us')}>
                Join us!
              </Button>
            </div>
          </div>

          <div className="text-xs text-center lg:text-right text-brown-light">
            &copy; Copyright Ktown Inc {currentYear}
          </div>
        </div>
      </div>

      <DynamicJoinUsModal />
    </footer>
  );
};

export default Footer;
