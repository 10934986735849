import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { useIntersection } from 'react-use';
import { CircularProgress } from '@material-ui/core';

import StorePreviewItem from '../store-preview-item/StorePreviewItem';
import { useStorePreviewInfinite } from '../../../api/hooks/stores';
import { StatusMessage, ProductLoader } from '../../common';
import { LayoutMode } from '../../../types/common';
import { ERROR_MESSAGE_GENERIC } from '../../../constants/common';
import { SearchQueryParams } from '../../../types/stores';

// import styles from './StoresList.module.scss';

// const FILTER_TAGS: string[] = [
//   'All',
//   'Vegan',
//   'Vegetarian',
//   'Udon',
//   'Sashimi',
//   'Drinks',
//   'Sweets',
// ];

type StoresListProps = {
  layoutMode: LayoutMode;
  featured?: boolean;
  limit?: number;
  searchQueryParams?: SearchQueryParams;
};

const StoresList = ({
  layoutMode,
  featured,
  limit = 12,
  searchQueryParams,
}: StoresListProps) => {
  //const [appliedFilter, setAppliedFilter] = useState<string>('All');

  const [noMoreResults, setNoMoreResults] = useState<boolean>(false);

  const listEndElement = useRef<HTMLDivElement | null>(null);

  const listEndIntersection = useIntersection(listEndElement, {
    rootMargin: '0px',
    threshold: 1,
  });

  const { data, error, isValidating, setSize } = useStorePreviewInfinite({
    params: {
      ...searchQueryParams,
      featured,
      limit,
    },
    config: {
      revalidateOnFocus: false,
    },
  });

  useEffect(() => {
    if (listEndIntersection?.isIntersecting) {
      setSize((size) => size + 1);
    }
  }, [listEndIntersection?.isIntersecting, setSize]);

  useEffect(() => {
    // Do not fetch new items if the previous response reached the end (no more items)s
    if (data?.length && data[data.length - 1].length < limit) {
      setNoMoreResults(true);
    }
  }, [data, limit]);

  if (error !== undefined) {
    return (
      <StatusMessage
        kind="error"
        message={error.message ?? ERROR_MESSAGE_GENERIC}
      />
    );
  }

  if (searchQueryParams?.search !== undefined && data?.[0]?.length === 0) {
    return (
      <div className="flex flex-col items-center py-20">
        <div className="mb-8">
          <Image
            src="/images/illustration-hand-fan.svg"
            alt="hand fan illustration"
            width={132}
            height={108}
          />
        </div>
        <strong className="mb-4 text-2xl font-semibold">
          Oops, no results were found for your search.
        </strong>
        <p>Try another query or use filters for a more accurate result</p>
      </div>
    );
  }

  return (
    <>
      <div
        className={cn('grid gap-5', {
          'md:grid-cols-2 xl:grid-cols-3': layoutMode === 'grid',
          'lg:grid-cols-2': layoutMode === 'list',
        })}
      >
        {!data ? (
          <>
            <ProductLoader />
            <ProductLoader />
            <ProductLoader />
            <ProductLoader />
            <ProductLoader />
            <ProductLoader />
          </>
        ) : (
          data.map((page) => {
            return page.map((storePreview) => (
              <Link
                key={storePreview.id}
                href={`/restaurants/${storePreview.id}`}
                passHref
              >
                <StorePreviewItem
                  layoutMode={layoutMode}
                  storePreview={storePreview}
                />
              </Link>
            ));
          })
        )}
      </div>

      {!featured && !noMoreResults && <div ref={listEndElement} />}

      {!!data && isValidating && (
        <div className="flex flex-col items-center mt-12 opacity-50">
          <CircularProgress
            color="inherit"
            className=" mb-6 text-brown-light"
          />
          <div className="text-brown-light font-medium lg:text-xl">
            Loading More Restaurants...
          </div>
        </div>
      )}
    </>
  );
};

export default StoresList;
