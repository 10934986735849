import React, { AnchorHTMLAttributes, forwardRef, useMemo } from 'react';
import Image from 'next/image';
import cn from 'classnames';

import { LayoutMode } from '../../../types/common';
import { StorePreview } from '../../../types/stores';
import { isDateTimeWithinTimeSpans } from '../../../helpers/figure/datetime';

import styles from './StorePreviewItem.module.scss';

export type StorePreviewItemProps = {
  layoutMode: LayoutMode;
  storePreview: StorePreview;
} & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'onClick'>;

const StorePreviewItem = forwardRef<HTMLAnchorElement, StorePreviewItemProps>(
  ({ layoutMode, storePreview, href, onClick }, ref) => {
    // Fallback to the placeholder image if store doesn't have any image
    const imageUrl =
      storePreview.images?.find((image) => image.type === 'preview')?.url ??
      '/images/store-image-placeholder.svg';

    const isOpen = useMemo<boolean>(() => {
      if (!storePreview?.hoursOfOperation?.spans) {
        return true;
      }

      return isDateTimeWithinTimeSpans(
        storePreview.hoursOfOperation.spans,
        new Date().toISOString(),
        storePreview.timezone ?? 'America/New_York'
      );
    }, [storePreview.hoursOfOperation, storePreview.timezone]);

    const hours = (
      <div className="flex items-center" data-testid="StorePreviewItem-hours">
        <span className={styles.label}>Hours:</span>
        <strong className={styles.value}>{isOpen ? 'Open' : 'Closed'}</strong>
      </div>
    );

    return (
      <a
        ref={ref}
        href={href}
        onClick={onClick}
        className={cn(styles.wrapper, {
          [styles.layoutGrid]: layoutMode === 'grid',
          [styles.layoutList]: layoutMode === 'list',
        })}
      >
        <div className={styles.imageWrapper}>
          <Image
            src={imageUrl}
            alt={storePreview.name + ' image'}
            layout="fill"
            objectFit="cover"
            className="rounded-md"
          />
        </div>

        {layoutMode === 'grid' && (
          <>
            <div className="flex justify-between items-center mb-3">
              <strong
                className="text-lg font-semibold leading-none"
                data-testid="StorePreviewItem-gridHeading"
              >
                {storePreview.name}
              </strong>
              {/*{!!storePreview.rating && (
                <div className="hidden md:block">
                  <Stars stars={storePreview.rating} />
                </div>
              )}*/}
            </div>

            <div className="justify-between md:flex">
              {!!storePreview.deliveryTimeRange &&
                storePreview.deliveryTimeRange.length == 2 && (
                  <div className="mb-2.5 md:mb-0 leading-none">
                    <span className={styles.label}>Delivery time:</span>
                    <span className={styles.value}>
                      {`${storePreview.deliveryTimeRange[0]} - ${storePreview.deliveryTimeRange[1]}`}
                      <span className="text-xs"> min</span>
                    </span>
                  </div>
                )}
            </div>

            <div className="flex items-center justify-between leading-none">
              {hours}
              {/*<div>
                <span className={styles.value}>
                  Mon, 4<span className="text-xs">PM</span>-10
                  <span className="text-xs">PM</span>
                  <Icon
                    icon="chevron-down"
                    alt="Show more"
                    className="inline-block ml-2"
                  />
                </span>
              </div>*/}

              {/*{!!storePreview.rating && (
                <div className="md:hidden">
                  <Stars stars={storePreview.rating} />
                </div>
              )}*/}
            </div>
          </>
        )}

        {layoutMode === 'list' && (
          <div className="flex flex-col justify-between">
            <h5
              className="leading-none"
              data-testid="StorePreviewItem-listHeading"
            >
              {storePreview.name}
            </h5>
            {hours}
          </div>
        )}
      </a>
    );
  }
);

export default StorePreviewItem;
