import {
  StorePreviewParams,
  StorePreview,
  Store,
  StoreMenu,
} from '../../types/stores';
import { composeDefaultHeaders } from '../index';
import { APIErrorResponse } from '../../types/common';
import {
  SWRCustomInfiniteUseRequest,
  SWRCustomUseRequest,
} from '../../types/swr';
import useRequest, { useRequestInfinite } from './index';

export const useStorePreview: SWRCustomUseRequest<
  StorePreviewParams,
  StorePreview[]
> = (args) =>
  useRequest<StorePreview[], APIErrorResponse>(
    {
      url: '/store-preview',
      headers: composeDefaultHeaders(),
      params: args?.params,
    },
    args?.config
  );

export const useStoreDetail: SWRCustomUseRequest<
  { id: string | undefined },
  Store
> = (args) =>
  useRequest<Store, APIErrorResponse>(
    {
      url: '/store/' + args?.params?.id,
      headers: composeDefaultHeaders(),
    },
    args?.config
  );

export const useStoreMenu: SWRCustomUseRequest<
  { id: string | undefined },
  StoreMenu
> = (args) =>
  useRequest<StoreMenu, APIErrorResponse>(
    {
      url: '/store/' + args?.params?.id + '/menu',
      headers: composeDefaultHeaders(),
    },
    args?.config
  );

export const useStorePreviewInfinite: SWRCustomInfiniteUseRequest<
  StorePreviewParams,
  StorePreview[]
> = (args) =>
  useRequestInfinite<StorePreview[], APIErrorResponse>(
    (size, previousPageData) => {
      // There are no more stores, return "null" to stop SWR from fetching more data
      if (previousPageData && !previousPageData.data) {
        return null;
      }

      return {
        url: '/store-preview',
        headers: composeDefaultHeaders(),
        params: {
          offset: size * args.params.limit,
          ...args.params,
        },
      };
    },
    args?.config
  );
